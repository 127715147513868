<template>
  <Slider
    :headline="headline"
    :headline-level="headlineLevel"
    :items="taxons"
    slider-type="taxon"
    :more-label="linkLabel"
    :more-href="link"
    :subtext="subtext"
  />
</template>

<script setup>
  import { deserialize } from "@alchemy_cms/json_api"

  const props = defineProps({
    element: {
      type: Object,
      default: () => ({}),
    },
  })

  const { api } = useApi()
  const { handleError } = useErrorHandling()
  const { getIngredient, getValue } = useAlchemy()
  const slideTaxons = props.element.nestedElements.map((taxonElement) => {
    return {
      id: getValue(taxonElement, "taxon"),
      altText: getValue(taxonElement, "alt_text"),
    }
  })

  const { data: taxons } = useLazyAsyncData(
    `${props.element.name}-${props.element.id}`,
    async () => {
      try {
        const data = await api("/jsonapi/taxons", {
          params: {
            filter: {
              id_in: slideTaxons.map((taxon) => taxon.id),
            },
          },
        })
        const taxonData = deserialize(data)

        // Need to maintain the original order of elements
        return slideTaxons.reduce((result, slideTaxon) => {
          const taxon = taxonData.find(
            (p) => p.id === slideTaxon.id?.toString(),
          )
          const imageAlt = slideTaxon.altText || taxon?.name
          if (taxon) {
            result.push({ ...taxon, imageAlt })
          }
          return result
        }, [])
      } catch (error) {
        handleError(error)
      }
    },
  )

  const headline = getValue(props.element, "headline")
  const headlineLevel = getIngredient(props.element, "headline")?.level || 3
  const link = getIngredient(props.element, "link")?.linkUrl
  const linkLabel = getValue(props.element, "link")
  const subtext = getValue(props.element, "subtext")
</script>
